import React from "react";
import { Link } from "react-router-dom"; 



const FeaturesHeroSection = ()=>{
    return(
      <>
        {/* <!-- Hero --> */}
      
      <section className="dark-mode container py-5 my-2 my-md-4 my-lg-5 vh-100 " >
        {/* <span className="d-dark-mode-none d-block position-absolute start-0 bottom-0 w-100 bg-white" style="height: 19.875vw;"></span>
        <span className="d-dark-mode-block d-none position-absolute start-0 bottom-0 w-100 bg-light" style="height: 19.875vw;"></span> */}
        <div className=" ">

        {/* Breadcrumb */}
          <nav className="pt-5" aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
              <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Features</li>
            </ol>
          </nav>

          {/* Page title */}
          <div className="mt-4 mb-lg-5 mb-4 py-3">
            <h1 className="display-5 mb-0">Orbix NDE Platform Features</h1>
          </div>

          <div className="d-flex justify-content-center">
            <img src="assets/features_img/features_hero.png" alt="Dashboard"/>
            
          </div>
        </div>
      </section>
      </>
    )
};

export default FeaturesHeroSection;