import React, { useEffect, useRef } from 'react';

const BookDemoButton = () => {
  const anchorRef = useRef(null);
  const scriptLoaded = useRef(false);
  const buttonLoaded = useRef(false);

  useEffect(() => {
    const loadButton = () => {
      console.log('Attempting to load button');
      if (
        window.calendar &&
        window.calendar.schedulingButton &&
        anchorRef.current &&
        !buttonLoaded.current
      ) {
        console.log('Loading button');
        window.calendar.schedulingButton.load({
          url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ25PYcnS8Dc8J-Cy4G0D4xgy-aD6LlI0LZzd3HMmVjcWmuhRADwCLxj7M8g2KxvGmZZ3ukiPl4q?gv=true',
          color: '#6366f1',
          label: 'Find Out How', // Updated button label
          id: 'bookaDemo',
          target: anchorRef.current,
        });
        buttonLoaded.current = true;
      }
    };

    if (!scriptLoaded.current) {
      const script = document.createElement('script');
      script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js';
      script.onload = () => {
        scriptLoaded.current = true;
        loadButton();
      };
      document.body.appendChild(script);
    } else {
      loadButton();
    }

    return () => {
      if (scriptLoaded.current && anchorRef.current) {
        anchorRef.current.innerHTML = '';
        buttonLoaded.current = false;
      }
    };
  }, []);

  return <div ref={anchorRef}></div>;
};

export default BookDemoButton;
