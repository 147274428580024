import React from "react";

const RealtimeCommunicationSection =() =>{
    return(
        <>
        <section className="container px-3 pt-5 mt-5  mb-3">
        <div className="row bg-secondary rounded-3 align-items-lg-center justify-content-center p-5">
          
          <div className="col-lg-5 col-md-5 offset-lg-1 text-center text-md-start">
            <h2 className="h2 mb-sm-4 ">Realtime Communication, Alerts, and Feedback</h2>
                <p className=" pb-2 pb-md-3 mb-3">
                Orbix's communication system creates a virtual 'always-on' workspace. Instant alerts, in-app messaging, and smart notifications connect field technicians and auditors in real-time, regardless of location. <br/>It's like having your entire team in the same room, all the time.
                </p>   
          </div>
          <div className="col-lg-6 col-md-7 mt-4  pt-2">
            <div className="pe-lg-5">
              <img src="assets/features_img/realtime_communication.png" class="rounded-3" alt="Image"/>
            </div>
          </div>
        </div>
      </section>

      </>
    )

}

export default RealtimeCommunicationSection;