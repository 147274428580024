import React from "react";

const MeetOrbixSection =()=>{
    return(
        <>

      
      <section className=" bg-secondary position-relative  pb-2 pb-lg-5 mb-3">
       {/* <!-- Item --> */}
        <div className="container">
            <div className="row  pb-5 mb-md-4 mb-lg-5 mt-5 pt-5 align-items-center ">
              <div className="col-md-6 pb-1 mb-3 pb-md-0 mb-md-0 order-2 order-md-1 mt-5 mt-md-0 " data-jarallax-element="-25" data-disable-parallax-down="md">
                <img src="assets/img/home_page/realtime_communication.png" className="d-block mx-auto" width="600" alt="grap"/>
              </div>
              <div className="col-md-6 pt-md-4 pt-lg-5 align-self-start order-1 order-md-2">
                    <div className="pe-md-4 me-md-2 ps-lg-5 ms-lg-5 ">
                    <p className="fs-lg text-white  mb-0">
                    <span className="fw-bold fs-5">Meet Orbix</span> the automated software platform that optimizes NDT job flow, automates data quality and reporting from the field, simplifies auditing, and maximizes efficiency for your inspection team.
                    </p>
                      <ul className="list-unstyled pb-2 pt-4">
                      <li className="d-flex align-items-center pb-1 mb-2">
                        <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                        Automate multiple inspection & reporting processes
                      </li>
                      <li className="d-flex align-items-center pb-1 mb-2">
                        <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                        Increase the efficiency of your Auditors and Level II’s & III’s 
                      </li>
                      <li className="d-flex align-items-center pb-1 mb-2">
                        <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                        Streamline inspections, auditing and reporting like never before
                      </li>
                    </ul>
                    <p className="fs-lg text-white  mb-0">
                    Because finding technicians is hard – but
                    optimizing their work shouldn’t be. Let's think about staffing differently.
                    </p>
                    <p className="fs-lg fw-bold text-primary mb-0 pt-4">
                    Amp up Your NDT Workforce with Automation.
                    </p>

                    </div>
              </div>
            </div>
        </div>
      </section>

        </>
    )
}

export default MeetOrbixSection