import React from "react";

const ItemsSection =()=>{
    return(
        <>

      
      <section className="container  border-bottom pb-2 pb-lg-5 mb-3">
      
       {/* <!-- Item --> */}
       <div className="row  pb-5 mb-md-4 mb-lg-5 mt-5">
          <div className="col-md-6 order-md-1 pb-1 mb-3 pb-md-0 mb-md-0" data-jarallax-element="-25" data-disable-parallax-down="md">
           
          <img src="assets/img/xpose/DigitalWorkflowsDiagram.svg" className="d-block mx-auto" width="746" alt="grap"/>
          
          </div>
          <div className="col-md-6 order-md-2 pt-md-4 pt-lg-5" data-jarallax-element="25" data-disable-parallax-down="md">
            <div className="pe-md-4 me-md-2 ps-lg-5 ms-lg-5 ">
              <h2 className="pb-3 pt-2 pt-md-0 pe-lg-5 me-lg-5 ">Hyper-Efficient Digital NDE Workflows</h2>
              <ul className="list-unstyled pb-2">
              <li className="d-flex align-items-center pb-1 mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                Digital NDE workflow management.
              </li>
              <li className="d-flex align-items-center pb-1 mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                Secure remote inspection and auditing. 
              </li>
              <li className="d-flex align-items-center pb-1 mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                Transparency and collaboration among stakeholders.
              </li>
              <li className="d-flex align-items-center pb-1 mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                Collaborative data management and cloud inspection.
              </li>
              <li className="d-flex align-items-center pb-1 mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                Impactful AI and computer vision systems.
              </li>
            </ul>

              <a href="#contact" className="btn btn-outline-primary">Learn more</a>
            </div>
          </div>
        </div>

        {/* <!-- Item --> */}
        <div className="row pb-5 mb-md-4 mb-lg-5 mt-5">
          <div className="col-md-6 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0" data-jarallax-element="-25" data-disable-parallax-down="md">
              <img src="assets/img/xpose/NDE_img.svg" className="rounded-3" width="600" alt="grap"/>
          </div>
          <div className="col-md-6 order-md-1 pt-md-4 pt-lg-5" data-jarallax-element="25" data-disable-parallax-down="md">
            <div className="pe-md-4 me-md-2">
              <h2 className="h3">Nondestructive Evaluation Reimagined</h2>
              <a href="#Learn" className="d-table badge bg-faded-primary text-primary fs-sm mb-3">Optimize Operations</a>
              <p className=" pb-3 mb-2 mb-md-3">Get more done with less and dramatically improve the quality and reliability of your Nondestructive tests.  Orbix enables unparalleled oversight and real-time monitoring of your NDE inspections, and the best part is, we are completely hardware agnostic so we can work with your existing hardware and systems. There is no need to invest in expensive hardware to obtain next generation functionality when you partner with Orbix. </p>
              <a href="#contact" className="btn btn-outline-primary">Learn more</a>
            </div>
          </div>
        </div>

      {/* <!-- Item --> */}
      <div className="row pb-5 mb-md-4  mt-5">
        <div className="col-md-6 pb-1 mb-3 pb-md-0 mb-md-0" data-jarallax-element="-25" data-disable-parallax-down="md">
            <img src="assets/img/xpose/project.svg" className="rounded-3" width="600" alt="graph"/>
        </div>
        <div className="col-md-6 pt-md-4 pt-lg-5" data-jarallax-element="25" data-disable-parallax-down="md">
          <div className="ps-md-4 ms-md-2">
            <h2 className="h3">Impactful AI and Machine Learning Systems</h2>
            <a href="#Learn" className="d-table badge bg-faded-primary text-primary fs-sm mb-3">AI Augmented Digital Workflows</a>
            <p className="pb-3 mb-2 mb-md-3">
            The difference in AI novelty and impactful AI lies in our ability to integrate these systems seamlessly into a digital workflow at scale. Truly impactful AI systems have tremendous potential to improve the quality and efficiency of NDE. There are many clever AI developments in NDE today, but these systems are being applied very narrowly. Orbix provides an open and interoperable framework to deploy AI systems  directly into a digital workflow at scale, enabling truly impactful AI.
            </p>
            <a href="#contact" className="btn btn-outline-primary">Learn more</a>
          </div>
        </div>
      </div>
      </section>

        </>
    )
}

export default ItemsSection