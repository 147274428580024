import React, { useState } from "react";
import BookDemoButton from "../../BookDemoButton";

const HeroSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <section className="container py-5 my-2 my-md-4 my-lg-5 vh-100">
      <div className="row align-items-center pb-2 py-xl-3">
        {/* Text Section */}
        <div className="col-xl-5 col-md-5 text-center text-md-start">
          <h1 className="display-5 pt-5 pb-2 pb-lg-3 mt-2 ">
            Struggling to Find Skilled NDT Technicians?
          </h1>
          <p className="fs-lg mb-4 mb-lg-5">
            You’re not alone. The talent shortage is real – but your workflow doesn’t have to suffer.
          </p>
          <BookDemoButton />
        </div>

       {/* Video Section */}
        <div className="col-md-7 offset-xl-0 mt-5 pt-5">
          <div className="position-relative rounded-3 overflow-hidden" style={{ border: "5px solid rgba(255, 255, 255, 0.15)" }}>
            {!isPlaying ? (
              <div
                className="position-relative w-100"
                style={{ cursor: "pointer" }}
                onClick={() => setIsPlaying(true)}
              >
                {/* Thumbnail */}
                <img
                  src="assets/img/home_page/video-thumbnail.png"
                  alt="Cover image"
                  className="w-100 rounded-1"
                />
                
                {/* Play Button */}
                <div className="position-absolute top-50 start-50 translate-middle zindex-5">
                  <button
                    className="btn btn-video btn-icon btn-xl stretched-link bg-white d-flex align-items-center justify-content-center"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      border: "none",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <i className="bx bx-play"></i>
                  </button>
                </div>

              </div>
            ) : (
              <div style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}>
                <iframe
                  src="https://www.youtube.com/embed/GReRpmRuSS4?autoplay=1&rel=0&modestbranding=1&controls=0"
                  title="YouTube video player"
                  className="rounded-3"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none"
                  }}
                  border="0"
                  allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>

            )}
          </div>
        </div>

      </div>
    </section>
  );
};

export default HeroSection;
