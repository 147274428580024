import React from 'react';
import './Modal.css';
import { setCookie } from './cookieHelper';

function Modal({ show, onClose, children }) {
  if (!show) {
    return null;
  }

  const handleNoThanks = () => {
    // Set a cookie to prevent the modal from showing for 14 days
    setCookie('modalDismissed', 'true', 14);
    onClose(); // Close the modal
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="modal-close">
          &times;
        </button>
        {children}
        <a className="no-thanks-text-custom" onClick={handleNoThanks}>NO THANKS</a>
      </div>
    </div>
  );
}

export default Modal;
