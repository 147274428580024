import React from "react";

const DigitalAuditWorkflowsSection =() =>{
    return(
        <>
      <section class="container  text-center pb-2 mt-n5 pt-n5 mt-md-4 pt-md-4 mt-lg-5 pt-lg-5">
          <h2 class="h1 pt-0 pt-md-5">Real-time Digital Inspection and Auditing Workflows</h2>
          <div class="row justify-content-center mb-md-2 mb-lg-5">
            <div class="col-md-10">
              <p class="fs-lg mb-lg-0">Experience unprecedented efficiency in NDE operations. Orbix real-time digital workflows slash audit cycle times by 99% and reduce skilled resource waste by up to 90%. Field data uploads instantly to the cloud, enabling immediate remote inspection and auditing This level of optimization transforms your operations, enabling rapid decision-making and significant resource savings.</p>
            </div>
          </div>
          
        </section>
       

        </>
    )

}

export default DigitalAuditWorkflowsSection;